const ARRROUTER = [
  {
    path: "/",
    name: "AppLanding",
    meta: { title: "ĐÌNH PHƯƠNG ĐỘ" },
    component: () => import("./pages/LandingDtwin.vue"),
  },
  {
    path: "/auto",
    name: "AppMappano",
    meta: { title: "TOUR TỰ ĐỘNG" },
    component: () => import("./pages/MapPano.vue"),
  },
  {
    path: "/tour",
    name: "AppTourVr",
    meta: { title: "TOUR 360" },
    component: () => import("./pages/TripDemo.vue"),
  },
];

export default function (path) {
  return ARRROUTER.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
