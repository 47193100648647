import APP_XWVGKLQSJN_ROUTER from "@/app_dinhphuongdo/router.js"
export const portalRouter = "";
export const dataMangeRouter = "manage";
export const systemMangeRouter = "manage/settings";
import APP_MYAPP_ROUTER from "@/app_myapp/router.js";
import APP_AUTH_ROUTER from "@/app_auth/router.js";
import APP_MANAGE_USER_ROUTER from "@/app_user/router.js";
import APP_MANAGE_SYSTEM_ROUTER from "@/app_system_manage/router.js";

export default [
    ...APP_MYAPP_ROUTER('system'),
    ...APP_MANAGE_USER_ROUTER('manage/user'),
    ...APP_AUTH_ROUTER(''),
    ...APP_MANAGE_SYSTEM_ROUTER('system/settings'),
    ...APP_XWVGKLQSJN_ROUTER(''),
];
